// This code imports the combineReducers function
// from the redux library and various other reducers from different files.
// It then uses the combineReducers function to combine all these individual
// reducers into a single root reducer. The resulting root reducer is
// then exported as the default export of this file. This root reducer is
// then passed to the createStore function in the store.js file to create the store,
// which is used to manage the global state of the application.

import { combineReducers } from 'redux';
import { languageReducer } from './changeLanguageReducers';
import { shareUrlReducer } from './shareUrlReducer';
import { tokenReducer } from './tokenReducer';
import { userDataReducer } from './userReducer';
import { userStatusReducer } from './userStatusReducer';
import { watermarkReducer } from './waterMarkReducers';
import { playingPercentReducer } from './playingPercent';
import { playerReducer } from './playersReducer';
import { colorReducer } from './colorReducer';
import { playedReducer } from './playedReducer';
import { boardReducer } from './boardReducer';

export default combineReducers({
  token: tokenReducer,
  user: userDataReducer,
  user_status: userStatusReducer,
  change_language: languageReducer,
  water_mark: watermarkReducer,
  shareUrl: shareUrlReducer,
  playingPercent: playingPercentReducer,
  all_player: playerReducer,
  colors: colorReducer,
  played: playedReducer,
  bg: boardReducer,
});
