import React, { useEffect, useState } from 'react';
import { baseURL } from '../resources/apiClient';
import { useSelector } from 'react-redux';
import http from '../resources/http';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const DailyReport = () => {
  const user = useSelector((state) => state.user);
  const [usersInfo, setUsersInfo] = useState({});

  const wallet = usersInfo?.wallet;
  const userId = user?.id;
  const [allBingo, setAllBingo] = useState([]);
  const [allBingos, setAllBingos] = useState([]);
  const userBingos = allBingo?.filter((bingo) => bingo.user.id === userId);

  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split('T')[0]
  );

  const today = new Date().toISOString().split('T')[0];
  const formattedDate = new Date(selectedDate + 'T00:00:00').toLocaleDateString(
    'en-US',
    { year: 'numeric', month: 'long', day: 'numeric' }
  );

  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    fetchBingos();
  }, []);

  const fetchBingos = () => {
    http
      .get(`${baseURL}api/account/profile/${userId}/`)
      .then((res) => {
        setUsersInfo(res.data);
      })
      .catch((err) => {
        // alert('Not Alert');
      });
    http
      .get(`${baseURL}api/bingo/games/`)
      .then((res) => {
        setAllBingo(res.data);
      })
      .catch((err) => {
        // alert('Not Alert');
      });
    http
      .get(`${baseURL}api/bingo/bingo-games/report/`)
      .then((res) => {
        setAllBingos(res.data);
      })
      .catch((err) => {
        // alert('Not Alert');
      });
  };

  const updateCalls = () => {
    http
      .patch(`${baseURL}api/bingo/games/${1167}/`, { total_calls: 100 })
      .then((res) => {
        fetchBingos();
      })
      .catch((err) => {
        // alert('Not Alert');
      });
  };

  const todayData = userBingos?.filter((item) =>
    item.created_at.startsWith(selectedDate)
  ); // Filter data for the selected date

  const dailyReport = todayData.reduce(
    (acc, curr) => {
      acc.totalServiceFee += curr.service_fee;
      acc.numberOfRecords++;
      return acc;
    },
    { totalServiceFee: 0, numberOfRecords: 0 }
  );

  const calculateServiceFeeSum = (games) => {
    return games?.reduce((total, game) => total + game?.service_fee, 0);
  };
  const totalRevenue = calculateServiceFeeSum(
    allBingo?.filter((items) => items.total_calls > 5)
  );
  const fairGames = allBingo?.filter((items) => items.total_calls > 5);
  const getStartOfWeek = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDay();
    const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(currentDate.setDate(diff));
  };

  const getEndOfWeek = (startOfWeek) => {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    return endOfWeek;
  };

  const calculatePlayingPercentage = (stake, numberOfPlayers, serviceFee) => {
    const totalStake = stake * numberOfPlayers;
    const serviceFeePercentage = (serviceFee / totalStake) * 100;
    return 100 - serviceFeePercentage;
  };

  const generateWeeklyReport = (data) => {
    const today = new Date();
    const startOfWeek = getStartOfWeek(today);
    const endOfWeek = getEndOfWeek(startOfWeek);

    const weeklyData = data.filter((item) => {
      const createdAt = new Date(item.created_at);
      return createdAt >= startOfWeek && createdAt <= endOfWeek;
    });

    const report = {
      totalServiceFee: 0,
      numberOfRecords: weeklyData.length,
      records: [],
    };

    weeklyData.forEach((item) => {
      const playingPercent = calculatePlayingPercentage(
        item.stake,
        item.number_of_players,
        item.service_fee
      );
      report.totalServiceFee += item.service_fee;
      report.records.push({
        ...item,
        playing_percent: playingPercent.toFixed(2),
      });
    });

    return report;
  };

  const weeklyReport = generateWeeklyReport(allBingo);

  const navigate = useNavigate(); // hook from react-router-dom to navigate to different routes
  const formatDateTime = (selectedDate) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(selectedDate).toLocaleString('en-US', options);
  };
  const selectedDateFormatted = formatDateTime(selectedDate);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(allBingo?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="flex  bg-gray-800">
      {/* Sidebar */}
      <div className="w-1/5 bg-teal-800 text-white p-5">
        <h1 className="text-2xl font-bold mb-5">Arada Bingo</h1>
        <ul></ul>
      </div>

      {/* Main Dashboard Area */}
      <div className="flex-1 bg-gray-100">
        <div className="bg-gray-600 h-24 flex justify-between items-center p-4">
          <button
            onClick={() => navigate('/bingo-games')}
            className="bg-blue-800 p-2 rounded shadow-md text-white"
          >
            Back To Game
          </button>

          <div className="border text-white flex justify-start items-center w-56 border-gray-300 p-3 bg-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-20 h-10 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>{' '}
            <p className="mt-2">{user.email}</p>
          </div>
        </div>
        <div
          onClick={() => updateCalls()}
          className="border font-mono font-bold shadow-md text-black flex justify-between items-center border-gray-300 p-2 bg-gray-100"
        >
          <p className="p-2">CASHIER DASHBOARD {selectedDate}</p>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="p-2 border border-gray-300 rounded"
          />
        </div>

        <div className=" p-10">
          <div className="grid grid-cols-3 gap-3">
            <div className="bg-white h-36 p-2 rounded-lg shadow">
              <p className="font-mono text-xl font-bold">GAME HOUSE EARNING</p>
              <p className="font-mono text-3xl font-bold">
                {totalRevenue > 0 ? totalRevenue + 'ETB' : 0}
              </p>
              <div className="flex justify-end items-end">
                {/* class="w-8 h-8 text-green-500 bg-gray-200 rounded" */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8 text-green-500 bg-gray-200 rounded"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div>
            <div className="bg-white h-36 p-2 rounded-lg shadow">
              <p className="font-mono text-xl font-bold">GAME PLAYED</p>
              <p className="font-mono text-3xl font-bold">
                {fairGames?.length > 0 ? fairGames?.length : 0}
              </p>
              <div className="flex justify-end items-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8 text-green-500 bg-gray-200 rounded"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
                  />
                </svg>
              </div>
            </div>
            <div className="bg-white h-36 p-2 rounded-lg shadow">
              <p className="font-mono text-xl font-bold">
                {today === selectedDate ? 'TODAY' : formattedDate} GAMES PLAYED
              </p>
              <p className="font-mono text-3xl font-bold">
                {dailyReport?.numberOfRecords}
              </p>
              <div className="flex justify-end items-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8 text-green-500 bg-gray-200 rounded"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
                  />
                </svg>
              </div>
            </div>
            <div className="bg-white h-36 p-2 rounded-lg shadow">
              <p className="font-mono text-xl font-bold">
                {today === selectedDate ? 'TODAY' : formattedDate} EARNING
              </p>
              <p className="font-mono text-3xl font-bold">
                {dailyReport?.totalServiceFee > 0
                  ? dailyReport?.totalServiceFee + 'ETB'
                  : 0}
              </p>
              <div className="flex justify-end items-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8 text-green-500 bg-gray-200 rounded"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div>
            <div className="bg-white h-36 p-2 rounded-lg shadow">
              <p className="font-mono text-xl font-bold">WALLET BALANCE</p>
              <p className="font-mono text-3xl font-bold">
                {wallet > 0 ? wallet : 0}
              </p>
              <div className="flex justify-end items-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-8 h-8 text-green-500 bg-gray-200 rounded"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-10 bg-white">
            <p className="font-mono text-3xl">Weekly Game History</p>
            <table className="tables min-w-full bg-white mt-10 font-mono">
              <thead>
                <tr>
                  <th className="">ID</th>
                  <th className="">USERNAME</th>
                  <th className="">HOUSE</th>
                  <th className="">STAKE</th>
                  <th className="">PLAYERS</th>
                  <th className="">FEE</th>
                  <th className="">PERCENT</th>
                  <th className="">TOTAL CALLS</th>
                  <th className="">TOTAL</th>
                  <th className="">DATE TIME</th>
                  <th className="">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {allBingo
                  ?.slice(pagesVisited, pagesVisited + usersPerPage)
                  ?.map((item) => (
                    <tr key={item.id}>
                      <td className="">{item.id}</td>
                      <td className="">{item.user.username}</td>
                      <td className="">{item.user.market}</td>
                      <td className="">{item.stake}</td>
                      <td className="">{item.number_of_players}</td>
                      <td className="">{item.service_fee.toFixed(1)}</td>
                      <td className="">{item.playing_percent * 100}%</td>
                      <td className="">{item.total_calls}</td>
                      <td className="">
                        {item.stake * item.number_of_players}
                      </td>
                      <td className="">{formatDateTime(item.created_at)}</td>
                      <td>
                        <p
                          className={`${
                            item.total_calls > 4
                              ? 'bg-green-300 text-green-500'
                              : 'bg-red-300 text-red-500'
                          } px-1 rounded `}
                        >
                          {' '}
                          {item.total_calls > 4 ? 'Finished' : 'Aborted'}
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {allBingo?.length > 9 && (
              <div className="flex mt-4 justify-center items-center">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  activeClassName={'paginationActive'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'paginationBttns'}
                  previousLinkClassName={'previousBttn'}
                  nextLinkClassName={'nextBttn'}
                  disabledClassName={'paginationDisabled'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
