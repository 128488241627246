import React, { useState } from 'react';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import Watermark from 'react-awesome-watermark';
import { baseURL } from '../resources/apiClient';
import http from '../resources/http';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { amharic, orommifa } from '../data/translate';
import { change_language, playingPercent, color } from '../store/actions';
import { useEffect } from 'react';
import PassPlayers from '../Bingo/PassPlayers';

const AdminHeader = ({
  wallet,
  topText,
  setPlayingPercentage,
  handleShowPass,
  players,
  winners,
  setWinners,
  passedNumbers,
  setPassedNumbers,
  selectedNumbers,
  setSelectedNumbers,
}) => {
  const [showHideUser, setShowHideUser] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHideUser = () => setShowHideUser(!showHideUser); // function to toggle the visibility of an element
  const logout = () => {
    localStorage.removeItem('meme'); // function to remove the item "meme" from local storage
  };
  const navigate = useNavigate(); // hook from react-router-dom to navigate to different routes

  const colors = useSelector((state) => state.colors); // hook from react-redux to access the user data from the store

  const [showHide, setShowHide] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHide = () => setShowHide(!showHide); // function to toggle the visibility of an element
  const data = useSelector((state) => state.change_language); // hook from react-redux to access the data from the store

  const [showHideSidebar, setShowHideSidebar] = React.useState(false); // state hook to control the visibility of an element
  const toggleShowHideSidebar = () => setShowHideSidebar(!showHideSidebar); // function to toggle the visibility of an element
  const dispatch = useDispatch(); // hook from react-redux to dispatch actions to the store
  // Get array variable from local storage
  const percentage = useSelector((state) => state.playingPercent); // hook from react-redux to access the data from the store
  const playedGames = useSelector((state) => state.played);

  const playFreeAudio = () => {
    import(`../Bingo/voice/free.m4a`).then((module) => {
      const audio = new Audio(module.default);
      audio.play();
    });
  };

  const shouldShowButton = false;
  // playedGames === 3 || ((playedGames - 3) % 4 === 0 && playedGames > 3);
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);

  const [smallPass, setSmallPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const handlePass = () => setShowPass(!showPass);

  const generateRandomNumbers = () => {
    let numToGenerate;
    if (selectedNumbers.length >= 1 && selectedNumbers.length <= 10) {
      numToGenerate = 1;
    } else if (selectedNumbers.length >= 15 && selectedNumbers.length <= 30) {
      numToGenerate = 2;
    } else if (selectedNumbers.length >= 31 && selectedNumbers.length <= 74) {
      numToGenerate = 3;
    } else if (selectedNumbers.length >= 75 && selectedNumbers.length <= 100) {
      numToGenerate = 5;
    } else if (selectedNumbers.length >= 101 && selectedNumbers.length <= 150) {
      numToGenerate = 7;
    } else if (selectedNumbers.length >= 151 && selectedNumbers.length <= 200) {
      numToGenerate = 8;
    } else {
      numToGenerate = 1;
    }

    if (numToGenerate) {
      const filteredNumbers = selectedNumbers.filter(
        (num) => !winners.includes(num)
      );
      const passed = [];
      for (let i = 0; i < numToGenerate; i++) {
        const randomIndex = Math.floor(Math.random() * filteredNumbers.length);
        passed.push(filteredNumbers[randomIndex]);
        // Remove the chosen number from the array to avoid duplicates
        filteredNumbers.splice(randomIndex, 1);
      }
      setPassedNumbers(passed);
      handlePass();
    } else {
      setSmallPass(true);
    }
  };

  return (
    <div className="z-50 relative top-0 ">
      <nav class="bg-amber-700 h-16 text-white  border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div class="flex  flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <span class="flex items-center">
            <span class="hidden lg:block self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              Arada Bingo
            </span>
            <span class="xs:block sm:block md:block lg:hidden xl:hidden 2xl:hidden font-bold self-center text-xl  whitespace-nowrap dark:text-white">
              <svg
                onClick={() => toggleShowHideSidebar()}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 cursor-pointer"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                />
              </svg>
            </span>
          </span>

          <div className="flex justify-between items-center space-x-10 ">
            <span class=" flex items-center cursor-pointer sr-only">
              <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                <Link className="hover:text-white" to="/admin">
                  {' '}
                  {/* {data.templateImages} */}
                  Upload Templates
                </Link>
              </span>
            </span>

            {/* my_daily_report */}
            <div className="flex justify-center items-center space-x-2">
              {show ? <p className="text-red-300">{players}</p> : null}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-12 h-12 cursor-pointer "
                onClick={() => toggleShow()}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-12 h-12 cursor-pointer "
              onClick={() => navigate('/my_daily_report')}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>

            <button
              className="font-bold text-2xl"
              onClick={() => generateRandomNumbers()}
            >
              P
            </button>

            <span
              onClick={() => toggleShowHideUser()}
              class="flex items-center cursor-pointer"
            >
              <span className="text-center uppercase pt-1.5 bg-sky-800 text-white rounded-full  h-10 w-10">
                A
              </span>
              {showHideUser ? (
                <div className="z-50 right-6  bg-gray-500 text-white p-1 absolute flex flex-col top-14">
                  <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white text-center">
                    <p
                      onClick={() => {
                        setPlayingPercentage(0.2);
                        dispatch(playingPercent(0.2));
                        // toggleShowHideUser();
                      }}
                      className={`${
                        percentage === 0.2 ? 'bg-red-800 text-white' : ''
                      }`}
                    >
                      {' '}
                      20%
                    </p>
                    <p
                      onClick={() => {
                        setPlayingPercentage(0.25);
                        dispatch(playingPercent(0.25));
                      }}
                      className={`${
                        percentage === 0.25 ? 'bg-red-800 text-white' : ''
                      }`}
                    >
                      25%
                    </p>
                    <p
                      onClick={() => {
                        setPlayingPercentage(0.3);
                        // toggleShowHideUser();
                        dispatch(playingPercent(0.3));
                      }}
                      className={`${
                        percentage === 0.3 ? 'bg-red-800 text-white' : ''
                      }`}
                    >
                      30%
                    </p>
                    <p className="hover:text-white">W- {wallet}</p>

                    <Link
                      onClick={() => {
                        logout();
                        // toggleShowHideUser();
                      }}
                      className="hover:text-white"
                      to="/"
                    >
                      {' '}
                      Logout
                    </Link>
                  </span>
                </div>
              ) : null}
            </span>
          </div>
        </div>
      </nav>
      <div className="flex justify-between text-center text-white">
        <div
          onClick={() => dispatch(color('bg-black'))}
          className="bg-black w-full cursor-pointer"
        >
          Black
        </div>
        <div
          onClick={() => dispatch(color('bg-blue-900'))}
          className="bg-blue-500 w-full cursor-pointer"
        >
          Blue
        </div>
        <div
          onClick={() => dispatch(color('bg-green-500'))}
          className="bg-green-500 w-full cursor-pointer"
        >
          Green
        </div>
        <div
          onClick={() => dispatch(color('bg-yellow-600'))}
          className="bg-yellow-600 w-full cursor-pointer"
        >
          Yellow
        </div>
      </div>
      {showPass && (
        <PassPlayers
          closeModal={handlePass}
          current={passedNumbers}
          selectedNumbers={passedNumbers}
        />
      )}
    </div>
  );
};

export default AdminHeader;
