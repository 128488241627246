// This code is the entry point of the React application.
// It renders the main App component wrapped in a Provider component that provides the redux store to the entire application.
// The BrowserRouter component is also wrapped around the App component to enable client-side routing.
// Additionally, it imports and uses the reportWebVitals function, which is used to measure the performance of the application,
// and the "react-slideshow-image/dist/styles.css" and "bootstrap/dist/css/bootstrap.css" for styling.
// Also, it creates the root node for ReactDOM to render the component and append it to the element with id "root" in the HTML file.

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.css';

import 'react-slideshow-image/dist/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
