import React from 'react';

const BingoBoardNotFound = () => {
  // Define the content for each cell
  const boardContent = [
    ['B', 'O', 'A', 'R', 'D'],
    ['', 'N', 'O', 'T', ''],
    ['F', 'O', 'U', 'N', 'D'],
    ['', '', '', '', ''],
  ];

  return (
    <table className="mb-4 font-bold text-2xl text-white table-fixed w-full">
      <tbody>
        {boardContent.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, colIndex) => (
              <td
                key={colIndex}
                className="border border-gray-400 w-16 h-16 text-center"
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BingoBoardNotFound;
