import React, { useEffect, useRef } from 'react';
import arada from './arada.mp4';

const Arada = () => {
  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  useEffect(() => {
    playVideo();
  }, []);

  return (
    <div>
      Your browser does not support the video tag.gggg
      <video width="750" height="500" controls ref={videoRef}>
        <source src={arada} type="video/mp4" />
        Your browser does not support the video tag.gggg
      </video>
    </div>
  );
};

export default Arada;
