import React, { useEffect, useState, useCallback, useRef } from 'react';
import boardsAddis from './board.json';
import boardsDallol from './boards.json';
import NumberButton from './NumberButton';
import http from '../resources/http';
import { baseURL } from '../resources/apiClient';
import PlayerSelection from './PlayereSelection';
import { useDispatch, useSelector } from 'react-redux';
import AdminHeader from '../memeComponents/AdminHeader';
import BingoBoardNotFound from './BingoBoardNotFound';
import BingoBoard from './BingoBoard';
import Arada from './Arada';
import { voices } from './voices';
import DailyReport from './DailyReport';
import { board, played } from '../store/actions';
import prizes from './prize/prize';
const Board = ({ playVideo }) => {
  // Save array variable to local storage
  const dispatch = useDispatch();
  const saveArrayToLocalStorage = (key, array) => {
    localStorage.setItem('players', JSON.stringify(players));
  };

  const playersJSON = localStorage.getItem('players');
  const [passedNumbers, setPassedNumbers] = useState([]);

  // Get array variable from local storage
  const percentage = useSelector((state) => state.playingPercent); // hook from react-redux to access the data from the store
  const voice = localStorage.getItem('voice');
  const [gameId, setGameId] = useState(0);

  // Clear array variable from local storage
  const clearArrayFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };
  const [isRunning, setIsRunning] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const toggleCheck = () => setShowCheck(!showCheck);
  const [startShuffle, setStartShuffle] = useState(false);
  const [blinkNumber, setBlinkNumber] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const toggleConfirm = () => setShowConfirm(!showConfirm);
  const [started, setStarted] = useState(false);
  const user = useSelector((state) => state.user);
  const playedGames = useSelector((state) => state.played);
  const bg_color = useSelector((state) => state.bg);
  const wallet = user?.wallet;
  const market = user?.market;
  const userId = user?.id;
  const lastLogin = user?.last_login;
  const boards = market === 'dallol' ? boardsDallol : boardsAddis;
  console.log(lastLogin, 'lastLogin');
  const ref = useRef();
  // const [boards, setBoards] = useState([]);
  const [calledNumbers, setCalledNumbers] = useState(['FREE']);
  const [winInfo, setWinInfo] = useState(null);
  const [won, setWon] = useState(false);
  const [winningBoard, setWinningBoard] = useState([]);
  // State variables for number of players and stake
  const [numPlayers, setNumPlayers] = useState();
  const [players, setPlayers] = useState([]);
  const [playingPercentage, setPlayingPercentage] = useState(percentage);
  const [validPlayers, setValidPlayers] = useState([]);
  const [blocked, setBlocked] = useState();
  const playStake = market === 'dallol' ? 10 : 10;
  const [stake, setStake] = useState(playStake);

  const [selectedNumbers, setSelectedNumbers] = useState(players);
  console.log(validPlayers, 'validPlayers');
  const [winners, setWinners] = useState([]);
  const [prize, setPrize] = useState(0);
  const [sponsored, setSponsored] = useState([]);

  const [info, setInfo] = useState({ total: 0, thirtyPercent: 0 });
  const [boardNumber, setBoardNumber] = useState('');
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [shuffling, setShuffling] = useState(false);
  console.log(selectedBoard, 'boards');
  const [derash, setDerash] = useState(0); // Initialize derash state
  const [called, setCalled] = useState(0);
  const [pause, setPause] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const handleShowPass = () => setShowPass(!showPass);
  const [showPrize, setShowPrize] = useState(false);
  const togglePause = () => {
    setPause(!pause);
    //if (!pause) {
    //  playEndAudio();
    // } else {
    //playStartAudio();
    //}
  };
  useEffect(() => {
    const checkLoginTimeDifference = () => {
      const currentTime = new Date();
      const lastLoginTime = new Date(lastLogin);

      const timeDifferenceInMilliseconds = currentTime - lastLoginTime;
      const timeDifferenceInHours =
        timeDifferenceInMilliseconds / (1000 * 60 * 60);

      if (timeDifferenceInHours >= 6) {
        dispatch(played(0));
      }
    };

    checkLoginTimeDifference();
  }, [lastLogin, dispatch]);

  const [previousCalledNumberRef, setPreviousCalledNumber] = useState(null);
  const [volume, setVolume] = useState(1500); // Initial volume value
  const initialSpeed = 1500 + (3000 - volume);
  const [speed, setSpeed] = useState(initialSpeed);

  // Function to handle volume change
  const handleVolumeChange = (event) => {
    const newVolume = parseInt(event.target.value); // Get the new volume value from the input
    setVolume(newVolume); // Update the volume state
    const newSpeed = 1500 + (3000 - newVolume); // Calculate the new speed value, ensuring it's not less than 1500
    setSpeed(newSpeed); // Update the speed state
    // You can also perform additional actions here, like adjusting the speed of something else
  };
  useEffect(() => {
    if (calledNumbers.length > 1) {
      // Update the previous called number when a new number is added
      setPreviousCalledNumber(calledNumbers[calledNumbers.length - 2]);
    }
  }, [calledNumbers]);

  const [value, setValue] = useState(25);

  const increaseBy25 = () => {
    setStake((prev) => prev + 25);
  };

  const decreaseBy25 = () => {
    setStake((prev) => prev - 25);
  };

  useEffect(() => {
    // Calculate derash whenever players or stake change
    const newDerash = players.length * stake;
    setDerash(newDerash); // Update derash state
  }, [players, stake]);

  const shuffleButtons = () => {
    setShuffling(true);
    setTimeout(() => setShuffling(false), 10000); // Stop shuffling after 10 seconds
  };
  function getLetter(number) {
    if (number >= 1 && number <= 15) {
      return 'B';
    } else if (number >= 16 && number <= 30) {
      return 'I';
    } else if (number >= 31 && number <= 45) {
      return 'N';
    } else if (number >= 46 && number <= 60) {
      return 'G';
    } else if (number >= 61 && number <= 75) {
      return 'O';
    } else {
      return '0';
    }
  }
  const [usersInfo, setUsersInfo] = useState({});
  const [allBingo, setAllBingo] = useState([]);
  const userBingos = allBingo?.filter((bingo) => bingo.user.id === userId);
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

  const todayData = userBingos?.filter((item) =>
    item.created_at.startsWith(today)
  ); // Filter data for today

  const dailyReport = todayData.reduce(
    (acc, curr) => {
      acc.totalServiceFee += curr.service_fee;
      acc.numberOfRecords++;
      return acc;
    },
    { totalServiceFee: 0, numberOfRecords: 0 }
  );

  console.log(dailyReport);

  console.log(dailyReport, 'daily report');
  const newData = todayData?.map((item) => {
    const totalStake = item.stake * item.number_of_players;
    const serviceFeePercentage = (item.service_fee / totalStake) * 100;
    const playingPercent = serviceFeePercentage;
    return { ...item, playing_percent: playingPercent };
  });

  console.log(newData);

  console.log(newData, 'detail report');

  console.log(userBingos);
  console.log(userBingos, 'allBingo');
  const fetchBingos = () => {
    http
      .get(`${baseURL}api/account/profile/${userId}/`)
      .then((res) => {
        setUsersInfo(res.data);
      })
      .catch((err) => {
        // alert('Not Alert');
      });
    http
      .get(`${baseURL}api/bingo/games/`)
      .then((res) => {
        setAllBingo(res.data);
      })
      .catch((err) => {
        // alert('Not Alert');
      });
  };
  useEffect(() => {
    fetchBingos();
  }, [isRunning]);
  const handleSubmit = async () => {
    var _totals = players?.length - passedNumbers.length - sponsored?.length;
    var totalAmount = _totals * stake;
    // Calculate 30% of the total amount
    var thirtyPercent =
      totalAmount - (totalAmount - totalAmount * playingPercentage);
    var parsedNumber = parseInt(thirtyPercent);

    const userWallet = usersInfo?.wallet;

    if (userWallet > parsedNumber && stake > 9 ) {
      try {
        await http
          .post(`${baseURL}api/bingo/games/`, {
            number_of_players: _totals,
            stake: stake,
            playing_percent: playingPercentage,
            total_calls: 10,
          })
          .then((res) => {
            setGameId(res.data.id);
          });

        if (totalAmount > 51) {
          await http.patch(`${baseURL}api/account/profile/${userId}/`, {
            wallet: usersInfo?.wallet - parsedNumber,
          });
        }
        // playStartAudio();
        if (playedGames === 4) {
          dispatch(played(0));
        }
        startNewGame();
      } catch (error) {
        alert('Error creating Bingo game:', error);
      }
    } else if (players?.length < 6 && totalAmount < 51 ) {
      startNewGame();
    } else {
      alert(
        'No enough balance, maximum derash should be 100, Please recharge your wallet or bet amount should be 20 birr or more'
      );
    }
  };
  console.log('game id', gameId);
  const removePlayer = (playerToRemove) => {
    const parsedBoardNumber = parseInt(playerToRemove);
    const newPlayers = validPlayers.filter(
      (player) => player !== parsedBoardNumber
    );
    setBoardNumber('');
    setSelectedBoard(null);
    setValidPlayers(newPlayers); // Update the state with the modified array
    blockBoardAudio();
  };
  
  const handleBoardRetrieve = () => {
    const board = boards[parseInt(boardNumber) - 1];
    const parsedBoardNumber = parseInt(boardNumber);

    if (validPlayers.includes(parsedBoardNumber)) {
      setSelectedBoard(board);
    } else {
      setSelectedBoard(null);
      // alert('የተመረጠው ካርቴላ አልተመዘገበም');
    }
    setWinners([...winners, parsedBoardNumber]);
  };
  console.log(winners, 'winners');
  console.log(selectedBoard, 'setSelectedBoard');
  // Function to play audio for the called number
  const playWin = () => {
    setPause(true);
    import(`./${voice}/stop.mp3`).then((module) => {
      const audio = new Audio(module.default);
      audio.play();
    });
  };
  const playWrong = () => {
    setPause(true);
    import(`./${voice}/buzzer.mp3`).then((module) => {
      const audio = new Audio(module.default);
      audio.play();
    });
  };
  const playStartAudio = () => {
    // import(`./${voice}/start.mp3`).then((module) => {
    //   const audio = new Audio(module.default);
    //   audio.play();
    // });
  };

  // Function to play audio for the called number
  const playEndAudio = () => {
    // import(`./${voice}/stop.mp3`).then((module) => {
    //   const audio = new Audio(module.default);
    //   audio.play();
    // });
  };
  // Function to play audio for the called number
  const playNumberAudio = (num) => {
    import(`./${voice}/${num}.mp3`).then((module) => {
      const audio = new Audio(module.default);
      audio.play();
    });
  };
  const shuffleAudio = () => {
    if (isRunning) {
      import(`./voice/shuffleNew.m4a`).then((module) => {
        const audio = new Audio(module.default);
        audio.play();
      });
    }
  };
  const blockBoardAudio = () => {
    import(`./voice/blocked.mp3`).then((module) => {
      const audio = new Audio(module.default);
      audio.play();
    });
  };

  const clearBoard = () => {
    setPlayers([]);
    setValidPlayers([]);
    setSelectedNumbers([]);
  };

  const totalButtons = 75;
  const columns = 15;
  const rows = Math.ceil(totalButtons / columns);
  const buttons = [];
  const [textColor, setTextColor] = useState('#818589'); // Initial text color
  const [bulbColor, setBulbColor] = useState('black');

  const handleColorChange = () => {
    // Generate a random color
    if (!started) {
      setStartShuffle(true);
      // Change bulb color for 1 second
      setBulbColor('text-white');
      setTimeout(() => {
        setBulbColor('text-black');
      }, 13000);
    }
  };
  const [blinkedNumbers, setBlinkedNumbers] = useState([]);

  useEffect(() => {
    if (startShuffle) {
      shuffleAudio();
      const intervalId = setInterval(() => {
        // Generate 10 random numbers between 1 and 75
        const randomNumbers = Array.from(
          { length: 40 },
          () => Math.floor(Math.random() * 75) + 1
        );
        setBlinkedNumbers((prevNumbers) => {
          // Combine the new random numbers with the previous ones
          const updatedNumbers = [...prevNumbers, ...randomNumbers];
          setTimeout(() => {
            // Remove the 10 random numbers after 0.1 second
            setBlinkedNumbers((prevNumbers) =>
              prevNumbers.filter((number) => !randomNumbers.includes(number))
            );
          }, 400);
          return updatedNumbers;
        });
      }, 200); // Blink every 0.1 second

      // Clear interval after 15 seconds
      setTimeout(() => {
        clearInterval(intervalId);
        setCalledNumbers([]);
        setBlinkedNumbers([]);
        setStartShuffle(false);
      }, 15000);

      // Cleanup functionF
      return () => clearInterval(intervalId);
    }
  }, [startShuffle]); // Run effect only once on component mount

  const rowLabels = ['B', 'I', 'N', 'G', 'O'];
  for (let i = 0; i < rows; i++) {
    const rowButtons = [];
    // Add row label in the first column of each row
    rowButtons.push(
      <td
        className="h-full items-center text-center  bg-white"
        key={'label-' + i}
      >
        <button
          className={` flex justify-center items-center h-20 w-10  text-center -mt-4  text-5xl text-red-600 font-extrabold `}
        >
          {rowLabels[i]}
        </button>
      </td>
    );
    for (let j = 0; j < columns; j++) {
      const buttonIndex = i * columns + j;
      const isCalled = calledNumbers.includes(buttonIndex + 1);
      const textColors = isCalled ? 'text-white' : 'texts';
      const shouldBlink =
        called === buttonIndex + 1 &&
        blinkedNumbers.includes(buttonIndex + 1) &&
        startShuffle;

      if (buttonIndex < totalButtons) {
        rowButtons.push(
          <td className="" key={buttonIndex}>
            <p
              className={`flex justify-center items-center h-24 w-20 text-center  ${
                called === buttonIndex + 1 ? 'blink' : ''
              }  ${
                blinkedNumbers.includes(buttonIndex + 1) && startShuffle
                  ? 'blink-once text-white'
                  : ''
              } text-7xl font-bold  m-1 rounded-lg ${textColors}`}
            >
              {buttonIndex + 1}
            </p>
          </td>
        );
      }
    }
    buttons.push(<tr key={'row-' + i}>{rowButtons}</tr>);
  }
  const [selectedPrize, setSelectedPrize] = useState(null);

  const shouldShowButton =
    playedGames === 3 || ((playedGames - 3) % 4 === 0 && playedGames > 3);

  useEffect(() => {
    if (shouldShowButton && selectedNumbers.length > 49) {
      const randomPrize = prizes[Math.floor(Math.random() * prizes.length)];
      setSelectedPrize(randomPrize);
      setPrize(300);
      setShowPrize(true);
    } else {
      setSelectedPrize(null);
    }
  }, [shouldShowButton]);

  // if (shouldShowButton && isRunning) {
  //   alert('Prize');
  // }
  // Function to handle calculation
  const calculate = () => {
    // Calculate total amount
    var _totals = players?.length - passedNumbers?.length - sponsored?.length;
    var totalAmount = _totals * stake;

    // Calculate 30% of the total amount
    const thirtyPercent = totalAmount - totalAmount * playingPercentage;
    // Update info state variable with calculated values
    setInfo({ total: totalAmount, thirtyPercent:totalAmount >51?thirtyPercent:   totalAmount });
    setIsRunning(true);
    // Log results to the console
    console.log('Total Amount:', totalAmount);
    console.log('30% of Total Amount:', thirtyPercent);
  };

  var wonGame = false;

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  // Function to generate title based on the called number
  const generateNumberTitle = (number) => {
    if (number >= 1 && number <= 15) {
      return 'b' + number;
    } else if (number >= 16 && number <= 30) {
      return 'i' + number;
    } else if (number >= 31 && number <= 45) {
      return 'n' + number;
    } else if (number >= 46 && number <= 60) {
      return 'g' + number;
    } else if (number >= 61 && number <= 75) {
      return 'o' + number;
    }
  };
  // Total numbers available
  const totalNumbers = 75;
  const [audio, setAudio] = useState(true);
  const handleAudioOnPauseState = () => {
    if (started && !pause) {
      // If already started and not paused, play the end audio and set audio to false
      playEndAudio();
      setAudio(false);
    } else if (!started) {
      if (!pause) {
        // If not started and not paused, play the end audio and set audio to false
        playEndAudio();
        setAudio(false);
      } else {
        // If paused, play start audio
        playStartAudio();
      }
    }
  };
  const handlePause = () => {
    // Toggle the pause state after 1 second
    togglePause();

    if (!pause) {
      playEndAudio();
    }

    if (audio && !started) {
      handleAudioOnPauseState();
    }

    setStarted(true);
  };
  const [startPlaying, setStartPlaying] = useState(false);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    let interval;
    if (!pause) {
      if (isRunning) {
        interval = setInterval(() => {
          if (calledNumbers.length === totalNumbers) {
            // Stop when all numbers are called
            clearInterval(interval);
          } else {
            let num;
            do {
              num = getRandomNumber(1, totalNumbers);
            } while (calledNumbers.includes(num));
  
            // Add the called number to the list
            setCalledNumbers([...calledNumbers, num]);
            setCalled(num);
  
            // Generate the title for the number (e.g., "B5", "G55", etc.)
            const numberTitle = generateNumberTitle(num);
  
            // Use SpeechSynthesis to speak out the generated number
            const utterance = new SpeechSynthesisUtterance(numberTitle);
            window.speechSynthesis.speak(utterance);
  
            setPreviousCalledNumber(calledNumbers[calledNumbers.length - 1]);
          }
        }, speed);
      }
      return () => clearInterval(interval);
    }
  }, [pause, isRunning, calledNumbers, totalNumbers, getRandomNumber]);
  
  const lastThreeNumbers = calledNumbers?.slice(-6);
  const callNumber = () => {
    setStarted(true);
    let num;
    do {
      num = getRandomNumber(1, 75);
    } while (calledNumbers.includes(num));
    setCalledNumbers([...calledNumbers, num]);
    // Call out the number using Text-to-Speech (not supported in web environment)
    // Tts.speak(num);
  };
  const updateCalls = () => {
    http
      .patch(`${baseURL}api/bingo/games/${gameId}/`, {
        total_calls: calledNumbers.length,
      })
      .then((res) => {
        fetchBingos();
      })
      .catch((err) => {
        // alert('Not Alert');
      });
  };

  const [winningNumbers, setWinningNumbers] = useState([]);
  console.log(winningNumbers, 'winningNumbers');
  const checkWin = useCallback(
    (board) => {
      const winningNumbers = [];
      // Check rows
      for (let i = 0; i < 5; i++) {
        if (i === 2 && calledNumbers.includes(board[i][i])) continue;

        if (board[i].every((num) => calledNumbers.includes(num))) {
          winningNumbers.push(...board[i]);
        }
      }
      // Check columns
      for (let i = 0; i < 5; i++) {
        if (calledNumbers.includes(board[i][2])) continue;

        if (board.every((row) => calledNumbers.includes(row[i]))) {
          for (let j = 0; j < 5; j++) {
            winningNumbers.push(board[j][i]);
          }
        }
      }
      // Check diagonals
      if (
        board[0][0] &&
        board[1][1] &&
        board[3][3] &&
        board[4][4] &&
        (calledNumbers.includes(board[0][0]) ||
          calledNumbers.includes(board[4][4]))
      ) {
        if (board.every((row, i) => calledNumbers.includes(row[i]))) {
          for (let i = 0; i < 5; i++) {
            winningNumbers.push(board[i][i]);
          }
        }
      }
      if (
        board[0][4] &&
        board[1][3] &&
        board[3][1] &&
        board[4][0] &&
        (calledNumbers.includes(board[0][4]) ||
          calledNumbers.includes(board[4][0]))
      ) {
        if (board.every((row, i) => calledNumbers.includes(row[4 - i]))) {
          for (let i = 0; i < 5; i++) {
            winningNumbers.push(board[i][4 - i]);
          }
        }
      }
      return winningNumbers;
    },
    [calledNumbers]
  );

  useEffect(() => {
    const newWinningNumbers = [];
    boards.forEach((board) => {
      const numbers = checkWin(board);
      if (numbers.length > 0) {
        newWinningNumbers.push(...numbers);
      }
    });
    setWinningNumbers(newWinningNumbers);
  }, [calledNumbers, checkWin]);

  // useEffect(() => {
  //   boards.forEach((board, index) => {
  //     const winInfo = checkWin(board);
  //     if (winInfo) {
  //       setWinInfo({ ...winInfo, boardNumber: index + 1 });
  //       setWinningBoard(board); // Set the winning board
  //       // setIsRunning(false); // Stop generating numbers when a winner is found
  //       setWon(true);
  //     }
  //   });
  // }, [calledNumbers, checkWin]);

  useEffect(() => {
    if (winInfo) {
      const { type, line, boardNumber } = winInfo;
      let message = '';
      if (type === 'row') {
        message = `Row ${line + 1} on Board ${boardNumber}`;
      } else if (type === 'column') {
        message = `Column ${line + 1} on Board ${boardNumber}`;
      } else if (type === 'diagonal') {
        message = `Diagonal ${
          line === 0 ? 'top-left to bottom-right' : 'top-right to bottom-left'
        } on Board ${boardNumber}`;
      }
    }
  }, [winInfo]);

  // Function to convert boards data to JSON format
  const exportBoardsToJson = () => {
    const jsonData = JSON.stringify(boards, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'boards.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  // Function to reset the game state
  const resetGame = () => {
    setIsRunning(true);
    setCalledNumbers([]);
    setWinInfo(null);
    setWon(false);
    setWinningBoard([]);
    setPause(true);
  };

  // Function to start a new game
  const startNewGame = () => {
    // playStartAudio();
    calculate();
    resetGame();
    // You can add additional initialization logic here if needed
  };
  // Function to start a new game
  const pauseGame = () => {
    setPause(true);
    playEndAudio();
    // You can add additional initialization logic here if needed
  };
  const [color, setColor] = useState('white'); // Initial color is white
  const callNextNumber = () => {
    setStarted(true);
    if (started) {
      let num;
      do {
        num = getRandomNumber(1, totalNumbers);
      } while (calledNumbers.includes(num));
      setCalledNumbers((prevCalledNumbers) => [...prevCalledNumbers, num]);
      setPreviousCalledNumber(num);
      setCalled(num);
      playNumberAudio(generateNumberTitle(num));
      // speak({ text: generateNumberTitle(num) }); // Speak out the generated number
    } else {
      playStartAudio();
    }
  };

  const notifyWinner = (win) => {
    if (win) {
      playWin();
    } else {
      playWrong();
    }
  };

  const [randomGameInSet, setRandomGameInSet] = useState(
    Math.floor(Math.random() * 4) + 1
  );
  const [lastPlayedGames, setLastPlayedGames] = useState(0);

  // useEffect(() => {
  //   // Determine the game number within the current set of four games
  //   const currentGameInSet = ((playedGames - 1) % 4) + 1;

  //   // If starting a new set, reset the random game within the set
  //   if (playedGames - lastPlayedGames >= 4) {
  //     setRandomGameInSet(Math.floor(Math.random() * 4) + 1);
  //     setLastPlayedGames(playedGames);
  //   }

  //   // Check if the current game is the randomly chosen one in this set
  //   if (currentGameInSet === randomGameInSet) {
  //     toggleConfirm();
  //     dispatch(played(0));
  //   }
  // }, [playedGames, randomGameInSet, lastPlayedGames]);

  const bingoLetters = ['B', 'I', 'N', 'G', 'O'];

  return (
    <div className={`${isRunning ? bg_color : ''} max-h-max`}>
      {!isRunning ? (
        <AdminHeader
          wallet={usersInfo?.wallet}
          setPlayingPercentage={setPlayingPercentage}
          handleShowPass={handleShowPass}
          players={players.length}
          passedNumbers={passedNumbers}
          setPassedNumbers={setPassedNumbers}
          winners={winners}
          setWinners={setWinners}
          selectedNumbers={selectedNumbers}
          setSelectedNumbers={setSelectedNumbers}
        />
      ) : null}
      {!isRunning ? (
        <div className="p-10 bg-white ">
          {/* <div className="number-container">
            <button onClick={startAnimation}>Start Animation</button>
            <div className={`number ${color}`}>42</div>
          </div> */}
          {/* <Arada /> */}
          <div className="flex justify-between items-center pb-2 space-x-8">
            <div className="w-full  border rounded">
              <p className="flex justify-center items-center text-2xl font-bold">
                BET AMOUNT
              </p>
              <div className="flex items-center justify-center w-full p-2">
                <button
                  onClick={decreaseBy25}
                  className="flex-none border  text-black font-bold py-2 px-4 rounded-r"
                >
                  -
                </button>
                <input
                  type="number"
                  className="flex-grow bg-gray-200 text-center py-2 px-4"
                  placeholder="Enter number"
                  value={stake}
                  onChange={(e) => setStake(parseFloat(e.target.value))}
                />
                <button
                  onClick={increaseBy25}
                  className="flex-none border  text-black font-bold py-2 px-4 rounded-r"
                >
                  +
                </button>
              </div>
            </div>
            <div className="w-full h-full border rounded">
              <p className="animate-blink flex justify-center items-center text-2xl font-bold">
                GAME NUMBER
              </p>
              <p className="flex justify-center items-center text-4xl font-bold">
                {userBingos?.length}
              </p>{' '}
            </div>
            <div
              className={`w-full h-full border rounded ${
                usersInfo?.wallet >= 0 && usersInfo.wallet <= 5000
                  ? 'bg-red-600 text-white'
                  : usersInfo?.wallet >= 5001 && usersInfo.wallet <= 7000
                  ? 'bg-yellow-600 text-white'
                  : usersInfo?.wallet >= 7001 && usersInfo.wallet <= 10000
                  ? 'bg-green-600 text-white'
                  : usersInfo?.wallet > 10000
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-500'
              }`}
            >
              <p className="flex justify-center items-center text-2xl font-bold">
                WALLET STATUS
              </p>
              <p
                className={`flex justify-center items-center text-4xl font-bold `}
              >
                {usersInfo?.wallet && (
                  <>
                    {usersInfo.wallet >= 0 && usersInfo.wallet <= 5000
                      ? 'Warning'
                      : usersInfo.wallet >= 5001 && usersInfo.wallet <= 7000
                      ? 'Good'
                      : usersInfo.wallet >= 7001 && usersInfo.wallet <= 10000
                      ? 'Very Good'
                      : usersInfo.wallet > 10000
                      ? 'Excellent'
                      : 'Unknown'}
                  </>
                )}
              </p>{' '}
            </div>
          </div>
          <PlayerSelection
            selectedNumbers={selectedNumbers}
            setSelectedNumbers={setSelectedNumbers}
            setPlayers={setPlayers}
            setStake={setStake}
            setValidPlayers={setValidPlayers}
            validPlayers={validPlayers}
            passedNumbers={passedNumbers}
            setPassedNumbers={setPassedNumbers}
            winners={winners}
            setWinners={setWinners}
            showPassed={showPass}
            sponsored={sponsored}
            setSponsored={setSponsored}
          />
          <div className="flex justify-center items-center mt-3 space-x-4">
            <div className="w-full h-full border rounded">
              <div className="flex justify-center space-x-4 items-center text-2xl font-bold">
                <div className="flex justify-center items-center space-x-2 sr-only">
                  <button className="text-xl">Playing Percent</button>
                  <button
                    onClick={() => setPlayingPercentage(0.2)}
                    className="px-2 bg-orange-600 rounded"
                  >
                    20%
                  </button>
                  {/* <button
                    onClick={() => setPlayingPercentage(0.3)}
                    className="px-2 bg-orange-600 rounded"
                  >
                    30%
                  </button> */}
                </div>
                <div className="flex justify-center items-center rounded space-x-2">
                  <button
                    onClick={() => {
                      handleSubmit();
                      saveArrayToLocalStorage();
                      playVideo();
                    }}
                    className="bg-green-800 text-white tet-2xl "
                  >
                    Start Game
                  </button>
                  {validPlayers?.length > 0 ? (
                    <button
                      onClick={() => {
                        clearBoard();
                      }}
                      className="bg-red-800 text-white tet-2xl "
                    >
                      Clear
                    </button>
                  ) : null}
                </div>
              </div>{' '}
            </div>
          </div>
        </div>
      ) : null}

      {isRunning ? (
        <div className="flex flex-col items-top pt-6 p-2">
          {/* <div className="p-1 flex flex-col justify-center -mt-4 items-center">
            <div className="text-white flex justify-center items-center space-x-4 mb-4">
              <div className="text-white flex flex-col justify-center items-center border w-24 h-28 bg-black">
                <p className="text-4xl font-bold text-red-500">
                  {calledNumbers?.length === 0 ? '' : calledNumbers?.length}
                </p>
                <p>Total Calls</p>
              </div>
              <div className="text-white flex flex-col justify-center items-center border w-24 h-28 bg-black">
                <p className="text-4xl font-bold text-red-500">
                  {previousCalledNumberRef === 'FREE' ||
                  previousCalledNumberRef === null
                    ? ''
                    : getLetter(previousCalledNumberRef) +
                      previousCalledNumberRef}
                </p>
                <p>Prev. Call</p>
              </div>
            </div>
            <BingoBoard />
          </div> */}

          <div
            className={`${
              shuffling ? 'shuffle' : ''
            } flex flex-wrap justify-center w-full`}
          >
            <table className="w-full">
              <tbody>{buttons}</tbody>
            </table>
          </div>
        </div>
      ) : null}

      {isRunning ? (
        <div>
          <div className="flex justify-between text-center text-white">
            <div
              onClick={() => dispatch(board('bg'))}
              className="bg w-full cursor-pointer"
            >
              Default
            </div>
            <div
              onClick={() => dispatch(board('bg-black'))}
              className="bg-black w-full cursor-pointer"
            >
              Black
            </div>
            <div
              onClick={() => dispatch(board('bg-blue-900'))}
              className="bg-blue-500 w-full cursor-pointer"
            >
              Blue
            </div>
            <div
              onClick={() => dispatch(board('bg-green-500'))}
              className="bg-green-500 w-full cursor-pointer"
            >
              Green
            </div>
            <div
              onClick={() => dispatch(board('bg-yellow-600'))}
              className="bg-yellow-600 w-full cursor-pointer"
            >
              Yellow
            </div>
          </div>

          <div className=" flex justify-between items-start  border-t p-2 border-gray-500">
            <div className="flex flex-col justify-center items-center space-x-4">
              {console.log(lastThreeNumbers, 'called numbers')}
              <div className="flex justify-center items-center space-x-4">
                <div className="flex flex-col justify-center items-center space-x-4">
                  <div className="h-48 w-48 bg-yellow-500 rounded-full flex justify-center items-center relative">
                    <div className="h-32 w-32 flex flex-col justify-center items-center bg-white text-black rounded-full text-center">
                      <p className="text-4xl">{getLetter(called)}</p>{' '}
                      <p className="text-6xl"> {called} </p>
                    </div>
                  </div>
                  <p className="font-bold text-blue-400 text-1xl mt-1">
                    Last 5 Calls
                  </p>
                  <div className="flex  justify-center items-center space-x-4">
                    <div className="flex justify-center items-center">
                      <div className="h-10 w-10 flex flex-col justify-center items-center bg-green-600 text-black rounded-full text-center">
                        <p className=" text-center mt-3 text-white">
                          {getLetter(lastThreeNumbers?.[4])}
                          {lastThreeNumbers?.[4]}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="h-10 w-10 flex flex-col justify-center items-center bg-red-600 text-black rounded-full text-center">
                        <p className=" text-center mt-3 text-white">
                          {getLetter(lastThreeNumbers?.[3])}
                          {lastThreeNumbers?.[3]}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="h-10 w-10 flex flex-col justify-center items-center bg-blue-600 text-black rounded-full text-center">
                        <p className=" text-center mt-3 text-white">
                          {getLetter(lastThreeNumbers?.[2])}
                          {lastThreeNumbers?.[2]}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="h-10 w-10 flex flex-col justify-center items-center bg-red-600 text-black rounded-full text-center">
                        <p className=" text-center mt-3 text-white">
                          {getLetter(lastThreeNumbers?.[1])}
                          {lastThreeNumbers?.[1]}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <div className="h-10 w-10 flex flex-col justify-center items-center bg-yellow-600 text-black rounded-full text-center">
                        <p className=" text-center mt-3 text-white">
                          {getLetter(lastThreeNumbers?.[0])}
                          {lastThreeNumbers?.[0]}{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className=" flex  space-x-8">
                    <button
                      onClick={callNextNumber}
                      // className={`ml-4 w-40 h-10 ${
                      //   started ? '' : 'bg-green-600'
                      // } ${pause ? 'buttons' : 'bg-gray-300'} `}
                      className={`ml-4 w-40 h-10 ${
                        !started
                          ? 'bg-green-600 text-white'
                          : pause
                          ? 'buttons'
                          : 'bg-gray-300'
                      }`}
                      disabled={!pause}
                    >
                      {started ? 'Call Next Number' : 'Start New Game'}
                    </button>
                  </div>
                  <div className=" flex  space-x-8">
                    <button
                      onClick={() => {
                        handlePause();
                      }}
                      className={`ml-4 w-40 h-8 ${
                        pause ? 'buttons' : 'bg-red-700 text-white'
                      }`}
                    >
                      {pause ? 'Start' : 'Stop'} Auto
                    </button>
                  </div>
                  <div className="  space-x-8">
                    <button
                      onClick={() => {
                        // setIsRunning(false);
                        // setSelectedBoard([]);
                        // setPause(false);
                        toggleConfirm();
                      }}
                      className={`ml-4 w-40 h-8 ${
                        pause ? 'buttons' : 'bg-gray-300'
                      }`}
                      disabled={!pause}
                    >
                      Reset Board
                    </button>
                  </div>

                  <div className="flex justify-center items-center space-x-16 ">
                    <button
                      onClick={() => handleColorChange()}
                      className={`ml-4 w-40 h-8 ${
                        started ? 'bg-gray-300' : 'buttons'
                      }`}
                      disabled={started}
                    >
                      Shuffle
                    </button>
                  </div>

                  {/* <div className="mt-3 flex justify-center items-center space-x-8">
              <button
                onClick={() => removePlayer(blocked)}
                className="ml-4  px-4 py-2 bg-yellow-900 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Lock Player Board
              </button>
              <input
                id="boardNumber"
                type="number"
                className="border border-gray-300 w-48 rounded-md p-2"
                value={blocked}
                onChange={(e) => setBlocked(e.target.value)}
                min="1"
                max={boards.length}
                placeholder="Enter Board Number..."
              />
            </div> */}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center space-y-2 mt-16 h-full">
              <div className="flex justify-center items-center space-x-2 border">
                <p className="text-white">Select Voice:</p>
                <select
                  className="h-8 w-36"
                  id="threats"
                  value={voice}
                  onChange={(e) => {
                    localStorage.setItem('voice', e.target.value);
                  }}
                >
                  <option>{voice}</option>
                  {voices?.map((item) => (
                    <option value={item.value} key={item.id}>
                      {item.voice}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-64  flex justify-center items-center border space-x-4">
                <p className="text-white mt-2">Calling Speed:</p>
                <input
                  className="slider -mt-2"
                  type="range"
                  min="1000"
                  max="3000"
                  step="100"
                  value={volume}
                  onChange={handleVolumeChange}
                />
              </div>
              <div className=" flex flex-col justify-center items-center space-y-4">
                <input
                  id="boardNumber"
                  type="number"
                  className="border  border-gray-300 h-6 w-48  "
                  value={boardNumber}
                  onChange={(e) => setBoardNumber(e.target.value)}
                  min="1"
                  max={boards.length}
                  placeholder="Enter Board Number..."
                  disabled={!pause}
                />
                <button
                  onClick={() => {
                    handleBoardRetrieve();
                    toggleCheck();
                  }}
                  className={`ml-4 w-40 h-8 ${
                    pause ? 'buttons' : 'bg-gray-300'
                  }`}
                  disabled={!pause}
                >
                  Check The Winner
                </button>
              </div>
            </div>
            <div>
              <div></div>
              <div className="text-white flex justify-center items-center space-x-4 mt-16">
                <div className="text-white flex flex-col justify-center items-center border rounded-full  w-28 h-28 bg-green-700">
                  <p className="text-4xl font-bold text-white">
                    {calledNumbers?.length === 0 ? '' : calledNumbers?.length}
                  </p>
                  <p>Total Calls</p>
                </div>
                <div className="text-white flex flex-col justify-center rounded-full items-center border w-28 h-28 bg-orange-700">
                  <p className="text-4xl font-bold text-white">
                    {previousCalledNumberRef === 'FREE' ||
                    previousCalledNumberRef === null ||
                    previousCalledNumberRef === undefined
                      ? ''
                      : getLetter(previousCalledNumberRef) +
                        previousCalledNumberRef}
                  </p>
                  <p>Prev. Call</p>
                </div>
              </div>
              <div className="text-white flex justify-center items-center space-x-4 mt-2">
                {/* <div className="text-white text-center flex flex-col justify-center items-center border rounded-full  w-14 h-14 bg-teal-700">
                <p className="text-xl font-bold text-white mt-2">
                  {playingPercentage * 100}%
                </p>
              </div> 
              <div className="text-white text-center flex flex-col justify-center items-center border rounded-full  w-14 h-14 bg-sky-700">
                <p className="text-xl font-bold text-white mt-2">
                  P. {validPlayers?.length}
                </p>
              </div> */}
              </div>
            </div>
            <div className="">
              <div className="h-52 w-52 bg-red-500 rounded-full flex justify-center items-center relative">
                <div className="h-36 w-36 flex flex-col justify-center items-center bg-white text-black rounded-full text-center">
                  <p className="text-2xl">Amount</p>{' '}
                  <p className="text-6xl">
                    {selectedNumbers.length > 49
                      ? info.thirtyPercent - 100
                      : info.thirtyPercent}{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {isRunning &&
        shouldShowButton &&
        showPrize &&
        selectedNumbers.length > 49 && (
          <div
            id="defaultModal"
            class="flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div class="relative w-full max-h-full">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div class="p-4 md:p-5 text-center">
                  <h3 class="animate-pulse mb-2 text-2xl font-normal text-blue-500 dark:text-gray-400">
                    ለአሸናፊው
                  </h3>
                  {selectedPrize && (
                    <div className="h-3/4">
                      <p className="text-4xl font-bold">{selectedPrize.name}</p>
                      <img src={selectedPrize.image} alt={selectedPrize.name} />
                    </div>
                  )}
                  <button
                    onClick={() => {
                      setShowPrize(false);
                    }}
                    data-modal-hide="popup-modal"
                    type="button"
                    class="text-white mt-4 mb-2 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                  >
                    Let's Go....
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      {showConfirm && isRunning && (
        <div
          id="defaultModal"
          class="flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div class="relative w-full max-w-2xl max-h-full">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div class="p-4 md:p-5 text-center">
                <h3 class="mb-2 text-2xl font-normal text-gray-500 dark:text-gray-400">
                  Are you sure you want to reset the game?
                </h3>
                <h3 class="mb-5 text-lg font-normal text-red-500 dark:text-gray-400">
                  Remember the game can't be reversed if you reset it
                </h3>
                <button
                  onClick={() => {
                    updateCalls();
                    setIsRunning(false);
                    setSelectedBoard([]);
                    setValidPlayers(players);
                    setBlinkedNumbers([]);
                    setCalledNumbers([]);
                    setStarted(false);
                    setAudio(true);
                    setCalled(0);
                    setPause(false);
                    toggleConfirm();
                    setPassedNumbers([]);
                    dispatch(played(playedGames + 1));
                  }}
                  data-modal-hide="popup-modal"
                  type="button"
                  class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                >
                  Yes, I'm sure
                </button>
                <button
                  onClick={() => {
                    // setIsRunning(false);
                    // setSelectedBoard([]);
                    // setPause(false);
                    toggleConfirm();
                  }}
                  data-modal-hide="popup-modal"
                  type="button"
                  class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCheck && (
        <div
          id="defaultModal"
          class="flex  justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div class="relative max-h-full">
            <div class="relative bg-black rounded-lg shadow dark:bg-gray-700">
              <div class="p-4 md:p-5 text-center">
                {selectedBoard === null && <BingoBoardNotFound />}
                <div className=" text-white rounded-md flex-1 ">
                  {selectedBoard && (
                    <div className="flex flex-col items-center p-2">
                      <label
                        htmlFor="boardNumber"
                        className="text-lg font-bold mr-2"
                      >
                        Board Number {selectedBoard ? boardNumber : ''}
                      </label>
                      {selectedBoard && (
                        <div className="h-full">
                          <table className="h-full">
                            <tbody>
                              {/* Add a header row for B I N G O letters */}
                              <tr>
                                {bingoLetters.map((letter, index) => (
                                  <th
                                    key={index}
                                    className="text-6xl font-extrabold h-28 w-32 border  bg-red-900 text-white"
                                  >
                                    {letter}
                                  </th>
                                ))}
                              </tr>
                              {selectedBoard.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {row.map((number, colIndex) => {
                                    // Check if all numbers in this row are called
                                    const allNumbersCalledRow = row.every(
                                      (num) =>
                                        calledNumbers.includes(num) ||
                                        num === 'FREE'
                                    );

                                    // Check if all numbers in this column are called
                                    const allNumbersCalledCol =
                                      selectedBoard.every(
                                        (row) =>
                                          calledNumbers.includes(
                                            row[colIndex]
                                          ) || row[colIndex] === 'FREE'
                                      );

                                    // Check left-to-right diagonal
                                    const allNumbersCalledLeftToRight =
                                      rowIndex === colIndex
                                        ? selectedBoard.every(
                                            (row, index) =>
                                              calledNumbers.includes(
                                                row[index]
                                              ) || row[index] === 'FREE'
                                          )
                                        : false;

                                    // Check right-to-left diagonal
                                    const allNumbersCalledRightToLeft =
                                      rowIndex + colIndex ===
                                      selectedBoard.length - 1
                                        ? selectedBoard.every(
                                            (row, index) =>
                                              calledNumbers.includes(
                                                row[
                                                  selectedBoard.length -
                                                    1 -
                                                    index
                                                ]
                                              ) ||
                                              row[
                                                selectedBoard.length - 1 - index
                                              ] === 'FREE'
                                          )
                                        : false;

                                    // Define the corner positions
                                    const isTopLeftCorner =
                                      rowIndex === 0 && colIndex === 0;
                                    const isTopRightCorner =
                                      rowIndex === 0 &&
                                      colIndex === selectedBoard[0].length - 1;
                                    const isBottomLeftCorner =
                                      rowIndex === selectedBoard.length - 1 &&
                                      colIndex === 0;
                                    const isBottomRightCorner =
                                      rowIndex === selectedBoard.length - 1 &&
                                      colIndex === selectedBoard[0].length - 1;

                                    const corners = [
                                      selectedBoard[0][0], // Top left corner
                                      selectedBoard[0][
                                        selectedBoard[0].length - 1
                                      ], // Top right corner
                                      selectedBoard[
                                        selectedBoard.length - 1
                                      ][0], // Bottom left corner
                                      selectedBoard[selectedBoard.length - 1][
                                        selectedBoard[0].length - 1
                                      ], // Bottom right corner
                                    ];

                                    // Check if all four corners are called
                                    const allCornersCalled = corners.every(
                                      (corner) =>
                                        calledNumbers.includes(corner) ||
                                        corner === 'FREE'
                                    );

                                    // Determine if the current cell is a corner
                                    const isCorner =
                                      isTopLeftCorner ||
                                      isTopRightCorner ||
                                      isBottomLeftCorner ||
                                      isBottomRightCorner;

                                    // Determine background and text color
                                    let backgroundColor = 'black';
                                    let textColor = 'white';

                                    if (
                                      calledNumbers.includes(number) ||
                                      number === 'FREE'
                                    ) {
                                      backgroundColor = 'red';
                                    }
                                    if (allCornersCalled && isCorner) {
                                     backgroundColor = 'green'
 textColor = 'white';
                                    }
                                    if (
                                      allNumbersCalledRow ||
                                      allNumbersCalledCol ||
                                      allNumbersCalledLeftToRight ||
                                      allNumbersCalledRightToLeft ||
(allCornersCalled && isCorner) 
                                    ) {
                                      backgroundColor = 'green';
                                      textColor = 'black';
                                    }
                                    if (
                                      allNumbersCalledRow ||
                                      allNumbersCalledCol ||
                                      allNumbersCalledLeftToRight ||
                                      allNumbersCalledRightToLeft
                                    ) {
                                      wonGame = true;
                                    }

                                    return (
                                      <td
                                        key={colIndex}
                                        style={{
                                          color: textColor,
                                          background: backgroundColor,
                                        }}
                                        className="text-red-500 text-5xl font-extrabold h-20 w-24 border border-gray-400"
                                      >
                                        <p
                                          className={
                                            called === number
                                              ? 'blink text-white'
                                              : ''
                                          }
                                        >
                                          {number}
                                        </p>
                                      </td>
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {selectedBoard && (
                  <button
                    onClick={() => {
                      removePlayer(boardNumber);
                      toggleCheck();
                    }}
                    data-modal-hide="popup-modal"
                    type="button"
                    class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                  >
                    Block Board
                  </button>
                )}
                <button
                  onClick={() => {
                    // setIsRunning(false);
                    // setSelectedBoard([]);
                    // setPause(false);
                    toggleCheck();
                  }}
                  data-modal-hide="popup-modal"
                  type="button"
                  class="py-2.5 px-5 ms-3 text-sm font-medium text-white focus:outline-none bg-gray-600 rounded-lg border border-gray-200  focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  Cancel
                </button>
              </div>
              <div className="mb-4">
                {wonGame ? (
                  <p class="mb-4 animate-bounce text-center py-2.5 px-5 ms-3 text-6xl font-medium text-green-500 focus:outline-none  rounded-lg border   focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    GOOD BINGO
                  </p>
                ) : (
                  <p class="animate-bounce text-center py-2.5 px-5 ms-3 text-6xl font-medium text-white focus:outline-none  rounded-lg   focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                    BOARD NOT WON
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Board;
