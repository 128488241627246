import React from 'react';

const PassPlayers = ({ selectedNumbers, closeModal, current }) => {
  const selectedAll = selectedNumbers.length === 15;
  const showArrays = selectedAll ? selectedNumbers : current;
  const sortedArray = showArrays.sort(function (a, b) {
    return a - b;
  });

  return (
    <div
      id="defaultModal"
      class="flex  justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class=" relative w-full  h-3/4">
        <div class="relative flex flex-col border justify-center items-center bg-white text-white font-bold rounded-lg shadow dark:bg-gray-700">
          <h2 className="text-2xl font-bold mb-4 text-blue-400">
            Free Players
          </h2>
          <div className="flex justify-center items-center space-x-4 flex-wrap gap-6">
            {sortedArray.map((number, index) => (
              <button className="flex justify-center items-center space-x-4 bg-blue-900 text-white h-24 w-24 rounded">
                <p className="text-white text-7xl">{number}</p>
              </button>
            ))}
          </div>
          <button
            onClick={closeModal}
            className="bg-blue-500 text-white px-4 py-2 mt-4 rounded hover:bg-blue-600 mb-4"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PassPlayers;
