import image1 from './awash.jpg';
import image2 from './kurt.jpg';
import image3 from './shekla.jpg';
import image4 from './single.png';
import image5 from './turbo.jpg';
const prizes = [
  {
    id: 1,
    name: 'አዋሽ ተከሽኖ',
    image: image1,
  },
  {
    id: 2,
    name: 'ግማሽ ኪሎ ቁርጥ',
    image: image2,
  },
  {
    id: 3,
    name: 'ግማሽ ኪሎ ሸክላ',
    image: image3,
  },
  {
    id: 4,
    name: '10 ሲንግል ድራፍት',
    image: image4,
  },
  {
    id: 5,
    name: 'ተወዳጁ አራዳ ቱርቦ',
    image: image5,
  },
];

export default prizes;
