import { actionTypes } from '../actions';

const playingPercentReducer = (state = 0.2, action) => {
  //change URL TO SHARE IMAGE LINK
  switch (action.type) {
    case actionTypes.PLAYING_PERCENT:
      return action.payload;
    default:
      return state;
  }
};

export { playingPercentReducer };
