import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import Headers from '../memeComponents/Headers';
import { baseURL } from '../resources/apiClient';
import { Slide } from 'react-slideshow-image';
import {
  playingPercent,
  set_token,
  set_user_data,
  set_user_status,
} from '../store/actions';
import LoginHeder from './LoginHeder';
import FAQs from '../memeComponents/FAQs';
import { amharic } from './../data/translate';
import { change_language } from './../store/actions/index';
import { COUNTRY_CODE } from '../data/data';
import { TelegramShareButton } from 'react-share';
import arada from './aradabg.jpg';
import aradaVideo from './enjoy.mp4';
import aradaVideo2 from '../arada.mp4';

const slideshowImages = [arada, arada, arada, arada];

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.change_language);
  const percent = useSelector((state) => state.playingPercent);
  const [show, setShow] = useState(false);
  const toggleTerms = () => setShow(!show);
  const [width, setWidth] = useState(window.innerWidth);
  const [agree, setAgree] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const toggleViewPassword = () => setViewPassword(!viewPassword);
  const [faqs, setFaqs] = useState(false);
  const toggleFaqs = () => setFaqs(!faqs);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [muted, setMuted] = useState(true); // State to manage muted state
  const [videoSrc, setVideoSrc] = useState(aradaVideo); // State to manage current video source
  const videoRef = useRef(null); // Reference to the video element

  useEffect(() => {
    const nextImage = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === slideshowImages.length - 1 ? 0 : prevIndex + 1
      );
    };

    const slideshowInterval = setInterval(nextImage, 5000);

    return () => clearInterval(slideshowInterval);
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const submit = async (e) => {
    e.preventDefault();
    if (email === '') {
      swal(data.enterPhone);
    } else if (password === '') {
      swal(data.enterPassword);
    } else {
      try {
        axios
          .post(`${baseURL}api/account/login`, {
            username: email,
            password: password,
          })
          .then(
            (response) => {
              localStorage.setItem('percentage', JSON.stringify(0.3));
              localStorage.setItem('voice', 'voice');
              localStorage.setItem('played', 0);

              dispatch(set_token(response.data.token));
              dispatch(set_user_data(response.data.user));
              dispatch(
                set_user_status({
                  loggedIn: true,
                  token: response.data.token,
                })
              );
              if (response.data.user.is_active) {
                response.data.user.user_type === 'ADMIN'
                  ? navigate('/manage-users')
                  : navigate('/bingo-games');
              } else {
                swal('Your account is not active at this time.');
              }
            },
            (err) => {
              swal('Login Failed, Please try Again.').then(() => {
                if (isMobile) {
                  handleClick();
                }
              });
            }
          );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const phoneNumber = '9860';
  const message = 'ok';

  const handleClick = () => {
    window.open(`sms:${phoneNumber}?body=${message}`);
  };

  useEffect(() => {
    dispatch(change_language(amharic));
  }, []);

  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const toggleMute = () => {
    setMuted((prevMuted) => !prevMuted);
    if (videoRef.current) {
      videoRef.current.muted = !muted;
    }
  };

  const handleNextVideo = () => {
    // Switch video source
    setVideoSrc(videoSrc === aradaVideo ? aradaVideo2 : aradaVideo);
  };

  return (
    <>
      <div className="relative bg-slate-200 h-screen w-screen">
       
      
        <div className="absolute top-4 right-4 flex items-center space-x-2">
          <button
            className="bg-white text-blue-500 py-2 px-4 rounded shadow"
            onClick={toggleTerms}
          >
            Login
          </button>
          <button onClick={toggleMute} className="p-2 bg-white rounded-full">
            {muted ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 5.25v13.5m-7.5-13.5v13.5M9.75 12H6m12 0h-3.75M3.75 8.25h16.5m-16.5 7.5h16.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 9v6M17.25 9v6M3 3v18l6-6h6l6 6V3L15 9H9L3 3z"
                />
              </svg>
            )}
          </button>
          <button
            className="p-2 bg-white rounded-full"
            onClick={handleNextVideo}
          >
            Next Video
          </button>
        </div>

        <Modal show={show} onHide={toggleTerms}>
          <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={submit}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type={viewPassword ? 'text' : 'password'}
                  placeholder="******************"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
