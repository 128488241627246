import React, { useState, useEffect } from 'react';
import PassPlayers from './PassPlayers';
import { useDispatch, useSelector } from 'react-redux';

const PlayerSelection = ({
  setPlayers,
  setValidPlayers,
  validPlayers,
  winners,
  setWinners,
  selectedNumbers,
  setSelectedNumbers,
  sponsored,
  setSponsored,
}) => {
  const dispatch = useDispatch();

  const [smallPass, setSmallPass] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [total, setTotal] = useState(100);
  const [numToSponsor, setNumToSponsor] = useState('');
  const [passedNumbers, setPassedNumbers] = useState([]);
  const todaysSponsored = JSON.parse(localStorage.getItem('passedNumbers'));

  const colors = useSelector((state) => state.colors);
  const allPlayers = useSelector((state) => state.all_players);
  const playedGames = useSelector((state) => state.played);
  const user = useSelector((state) => state.user);

  const [currentGeneratedNumbers, setCurrentGeneratedNumbers] = useState([]);

  const handleButtonClick = (number) => {
    let newNumbers;
    if (selectedNumbers.includes(number)) {
      newNumbers = selectedNumbers.filter((num) => num !== number);
    } else {
      newNumbers = [...selectedNumbers, number];
    }
    setSelectedNumbers(newNumbers);
    setPlayers(newNumbers);
    setValidPlayers(newNumbers);
  };

  const handlePass = () => setShowPass(!showPass);

  // Function to generate random numbers for the players to sponsor
  const generateRandomNumbers = () => {
    const numToGenerate = parseInt(numToSponsor, 10);
    const remainingSlots = 15 - passedNumbers.length;

    if (numToGenerate && numToGenerate > 0 && numToGenerate <= remainingSlots) {
      const filteredNumbers = selectedNumbers.filter(
        (num) => !passedNumbers.includes(num)
      );
      const newPassed = [];
      for (let i = 0; i < numToGenerate; i++) {
        const randomIndex = Math.floor(Math.random() * filteredNumbers.length);
        newPassed.push(filteredNumbers[randomIndex]);
        filteredNumbers.splice(randomIndex, 1);
      }
      const updatedPassedNumbers = [...passedNumbers, ...newPassed];
      setPassedNumbers(updatedPassedNumbers);
      handlePass();
      setCurrentGeneratedNumbers(newPassed);
      // Save to local storage with timestamp
      const data = {
        numbers: updatedPassedNumbers,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('passedNumbers', JSON.stringify(data));

      // Set a timeout to clear it after 25 hours
      setTimeout(() => {
        localStorage.removeItem('passedNumbers');
        window.location.reload();
      }, 25 * 60 * 60 * 1000); // 25 hours in milliseconds
    } else if (numToGenerate > remainingSlots) {
      alert(`You can only add ${remainingSlots} more players.`);
    } else {
      setSmallPass(true);
    }
  };

  // Function to check and remove expired passed numbers
  const checkExpiredPassedNumbers = () => {
    const data = JSON.parse(localStorage.getItem('passedNumbers'));
    if (data) {
      const currentTime = new Date().getTime();
      if (currentTime - data.timestamp > 25 * 60 * 60 * 1000) {
        // 25 hours in milliseconds
        localStorage.removeItem('passedNumbers');
        window.location.reload();
      } else {
        setPassedNumbers(data.numbers);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(checkExpiredPassedNumbers, 10000); // Check every 10 seconds
    return () => clearInterval(interval);
  }, []);

  const shouldShowButton =
    playedGames === 3 || ((playedGames - 3) % 4 === 0 && playedGames > 3);

  // Generate buttons numbered from 1 to total
  const buttons = [];
  for (let i = 1; i <= total; i++) {
    const isSelected = selectedNumbers.includes(i);
    buttons.push(
      <div key={i} className="inline-block flex-col m-0.5">
        <button
          className={`text-center text-3xl font-bold h-16 w-16 text-white ${
            isSelected ? 'bg-red-800' : `${colors} hover:bg-black`
          }`}
          onClick={() => handleButtonClick(i)}
        >
          {i}
        </button>
      </div>
    );
  }

  // Retrieve selected numbers from local storage when the component mounts
  useEffect(() => {
    const storedNumbers = JSON.parse(localStorage.getItem('selectedNumbers'));
    if (storedNumbers) {
      setSelectedNumbers(storedNumbers);
      setPlayers(storedNumbers);
      setValidPlayers(storedNumbers);
    }
    checkExpiredPassedNumbers(); // Initial check for expired passed numbers
  }, [setPlayers, setValidPlayers]);

  // Save selected numbers to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('selectedNumbers', JSON.stringify(selectedNumbers));
  }, [selectedNumbers]);

  // Function to clear passed numbers
  const clearPassedNumbers = () => {
    localStorage.removeItem('passedNumbers');
    setPassedNumbers([]);
    setCurrentGeneratedNumbers([]);
    window.location.reload();
  };

  // Function to activate sponsorship
  const activateSponsorship = () => {
    setSponsored(todaysSponsored?.numbers);
  };

  return (
    <div className="border">
      <div className="flex justify-around items-center">
        <p className="flex justify-center items-center font-extrabold text-3xl">
          PLAYERS BOARD NUMBER
        </p>
        {false && (
          <div>
            {passedNumbers.length < 15 ? (
              <div className="flex justify-center items-center space-x-4">
                <input
                  type="number"
                  id="num_to_sponsor"
                  className="px-1.5 h-10 w-44 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="How many players?"
                  value={numToSponsor}
                  onChange={(e) => setNumToSponsor(e.target.value)}
                  required
                />
                <button
                  onClick={generateRandomNumbers}
                  className="h-10 w-44 bg-green-500 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  Sponsor
                </button>
              </div>
            ) : (
              <div className="flex space-x-4">
                <button
                  onClick={handlePass}
                  className="h-10 w-44 bg-blue-500 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  Show Sponsored Players
                </button>
                <button
                  onClick={activateSponsorship}
                  className={`${
                    sponsored?.length === 15 ? 'animate-bounce' : ''
                  } bg-yellow-500 text-white px-4 py-2 rounded`}
                >
                  {sponsored?.length === 15 ? 'Sponsoring' : 'Activate Sponsor'}
                </button>
                <button
                  onClick={clearPassedNumbers}
                  className="h-10 w-44 bg-red-500 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  Clear
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-center">{buttons}</div>
      <div className="flex flex-wrap justify-center">
        {total < 101 ? (
          <button
            className="bg-green-500 text-white px-4 py-2 rounded mb-4 m-4"
            onClick={() => setTotal(200)}
          >
            100-200
          </button>
        ) : (
          <button
            className="bg-green-500 text-white px-4 py-2 rounded mb-4 ml-4"
            onClick={() => setTotal(100)}
          >
            1-100
          </button>
        )}
      </div>
      {/* Display passed numbers */}
      {showPass && (
        <PassPlayers
          closeModal={handlePass}
          current={currentGeneratedNumbers}
          selectedNumbers={passedNumbers}
        />
      )}
      {/* Add the "Activate Sponsor" button */}
      <div className="flex justify-center mt-4"></div>
    </div>
  );
};

export default PlayerSelection;
