// This code exports a set of action types and action creators that are used in a Redux store.
// The action types include SET_TOKEN, SET_USER_STATUS, SET_USER_DATA, SET_LANGUAGE, and WATER_MARK_TEXT.
// The action creators include water_mark, change_language, set_user_status, set_user_data, and set_token.
// These actions allow for updating the state of the store by dispatching actions with a payload of data.

export const actionTypes = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER_STATUS: 'SET_USER_STATUS',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_LANGUAGE: 'SET_LANGUAGE',
  WATER_MARK_TEXT: 'WATER_MARK_TEXT',
  SHARE_URL: 'SHARE_URL',
  PLAYING_PERCENT: 'PLAYING_PERCENT',
  PLAYERS: 'PLAYERS',
  COLOR: 'COLOR',
  BOARD_COLOR: 'BOARD_COLOR',
  PLAYED: 'PLAYED',
  NUM_OF_PLAY: 'NUM_OF_PLAY',
};

export const playedTimes = (payload) => {
  return {
    type: actionTypes.NUM_OF_PLAY,
    payload,
  };
};
export const played = (payload) => {
  return {
    type: actionTypes.PLAYED,
    payload,
  };
};
export const all_players = (payload) => {
  return {
    type: actionTypes.PLAYERS,
    payload,
  };
};
export const color = (payload) => {
  return {
    type: actionTypes.COLOR,
    payload,
  };
};
export const board = (payload) => {
  return {
    type: actionTypes.BOARD_COLOR,
    payload,
  };
};
export const water_mark = (payload) => {
  return {
    type: actionTypes.WATER_MARK_TEXT,
    payload,
  };
};

export const change_language = (payload) => {
  return {
    type: actionTypes.SET_LANGUAGE,
    payload,
  };
};
export const set_user_status = (payload) => {
  return {
    type: actionTypes.SET_USER_STATUS,
    payload,
  };
};

export const set_user_data = (payload) => {
  return {
    type: actionTypes.SET_USER_DATA,
    payload,
  };
};

export const set_token = (payload) => {
  return {
    type: actionTypes.SET_TOKEN,
    payload,
  };
};
export const shareUrl = (payload) => {
  return {
    type: actionTypes.SHARE_URL,
    payload,
  };
};
export const playingPercent = (payload) => {
  return {
    type: actionTypes.PLAYING_PERCENT,
    payload,
  };
};
