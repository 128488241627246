import React, { useEffect, useState } from 'react';
import boardsData from './board.json'; // Assuming you have your JSON data in a file named boards.json

const PrintBoards = () => {
  const [bingoBoards, setBingoBoards] = useState([]);

  useEffect(() => {
    // Assuming the JSON data structure is an array of arrays
    setBingoBoards(boardsData);
  }, []);

  return (
    <div className="flex flex-wrap justify-center">
      {bingoBoards.map((board, index) => (
        <div key={index} className="mx-4 my-8 board-container">
          <h3 className="text-center mb-2">Board #{index + 1}</h3>
          <table className="board-table">
            <thead>
              <tr>
                {['B', 'I', 'N', 'G', 'O'].map((letter, i) => (
                  <th key={i} className="px-4 py-2 text-center">
                    {letter}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {board.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((number, colIndex) => (
                    <td key={colIndex} className={`px-4 py-2 text-center`}>
                      {number === 'FREE' ? (
                        'FREE'
                      ) : (
                        <span className="text-red-500">{number}</span>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default PrintBoards;
