import { actionTypes } from '../actions';

const colorReducer = (state = 'bg-green-500', action) => {
  //change language reducer
  switch (action.type) {
    case actionTypes.COLOR:
      return action.payload;
    default:
      return state;
  }
};

export { colorReducer };
